.layout {
	&-wrapper {
		height: 100%;
		padding: 40px 0;
	}
	&-main {
		max-width: 100%;
		margin: 0 auto;
		padding: 63px 0 0 0;
		background: #fff;
		overflow: hidden;
	}
	&-inner {
		padding: 0;
		position: relative;
	}
}

@media (max-width: 1400px) {
	.layout {
		&-wrapper {
			height: 100%;
			padding: 0;
		}
		&-main {
			padding: 48px 0 0 0;
		}
	}
}