.form {
	width: 30%;
	min-width: 300px;
	margin: 0 auto;
	&-label {
		display: block;
		margin: 0 0 20px;
		a {
			color: #333;
			text-decoration: underline;
			&:hover {
				text-decoration: none;
				opacity: .7;
			}
		}
	}
	&-check {
		margin-right: 5px;
		&-wrapper {
			a {
				color: #333;
				text-decoration: underline;
				&:hover {
					text-decoration: none;
					opacity: .7;
				}
			}
		}
	}
}

.item {
	margin: 0 0 10px;
}

.form-oauth {
	button {
		cursor: pointer;
		padding: 6px 16px;
    font-size: 0.875rem;
		min-width: 64px;
		border: none;
		line-height: 1.75;
		&:hover {
			opacity: .7;
		}
	}
}


@media (max-width: 767px) {
	.form {
		width: 100%;
		min-width: initial;
    min-width: auto;
	}
}