.profile {
	&-wrapper {
		padding: 3rem 24px;
		display: flex;
		border-bottom: 1px solid rgba(0,0,0,.1);
		&:last-child {
			border: none;
		}
	}
	&-info {
    flex-basis: calc(50% - 16px);
		flex-grow: 0;
		img {
			width: 100%;
		}
		p {
			line-height: 1.8;
			font-size: 1.6rem;
		}
	}
	&-name {
		text-align: center;
		font-size: 2.7rem !important;
		margin: 0 0 10px;
	}
	&-twitter {
    margin-left: 32px;
    flex-basis: calc(50% - 16px);
    flex-grow: 0;
	}
}


@media (max-width: 768px) {
	.profile {
		&-wrapper {
			display: block;
		}
		&-twitter {
			margin-left: 0;
		}
	}
}