.cart-item {
	color: #3e3e3e;
	margin: 0 0 10px;
	dl {
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid #3e3e3e;
		margin: 0;
		div {
			width: 100%;
			&:nth-child(3) {
				dt {
					text-align: right;
				}
				dd {
					text-align: right;
				}
			}
		}
		dt {
			border-bottom: 2px solid #3e3e3e;
			font-weight: bold;
			padding: 10px;
			text-align: center;
		}
		dd {
			margin: 0;
			padding: 10px;
			text-align: center;
			span {
				cursor: pointer;
			}
		}
		+ dl {
			dt {
				display: none;
			}
		}
	}
}


@media (max-width: 825px) {
	.cart-item {
		color: #3e3e3e;
		margin: 0 0 30px;
		dl {
			display: block;
			margin: 0 0 20px;
			border: none;
			div {
				display: flex;
				justify-content: space-between;
				border-bottom: 1px solid #3e3e3e;
			}
			dt {
				border-bottom: none;
			}
			+ dl {
				dt {
					display: block;
				}
			}
		}
	}
}


.cart-total {
	color: #3e3e3e;
	float: right;
	width: 30%;
	margin: 0;
	div {
		display: flex;
		justify-content: flex-end;
		border-bottom: 1px solid #3e3e3e;
		&:last-child {
			border-bottom: none;
			dt,
			dd {
				font-size: 16px;
				font-weight: bold;
			}
		}
		dt ,
		dd {
			width: 50%;
			margin: 0;
			padding: 10px;
		}
		dt {
			text-align: left;
			font-weight: bold;
		}
		dd {
			text-align: right;
		}
	}
}

.cart-nothing {
	font-size: 2rem;
	text-align: center;
	padding: 40px 0;
}

@media (max-width: 825px) {
	.cart-total {
		width: 100%;
	}
}

