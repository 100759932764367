@import "~normalize.css/normalize.css";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');

html {
	font-size: 62.5%;
}

body {
	font-family: 'Noto Sans JP', 'Open Sans',Helvetica,YuGothic,Yu Gothic,'ヒラギノ角ゴ Pro W3',Hiragino Kaku Gothic Pro,"メイリオ",Meiryo,"ＭＳＰゴシック",sans-serif;
	font-size: 16px;
	line-height: 1.5;
	background: #fff;
	color: #333;
}

html ,
body {
	height: 100%;
	margin: 0;
}

* {
	box-sizing: border-box;
}

#root {
	height: 100%;
}

a {
  color: #000000;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.cms-content {
  overflow: hidden;
  line-height: 1.9;
  background: #fff;
  padding: 0 24px;
  width: 100%;
  font-size: 1.6rem;
  a {
    font-weight: 700;
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  p {
    overflow: hidden;
    margin: 0 0 16px;
  }
  code[class*="language-"], pre[class*="language-"] {
    color: black;
    text-shadow: 0 1px white;
    direction: ltr;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5;
    tab-size: 4;
    hyphens: none;
  }
  pre[class*="language-"] {
    background: #d9d9d9;
  }
  pre[class*="language-"] {
    padding: 1em;
    margin: .5em 0;
    overflow: auto;
	}
	ul {
		padding: 0;
	}
	ul li {
		list-style-type: none;
	}
  table {
    border-top: 1px solid #ccc;
    border-collapse: collapse;
    width: 100% !important;
    tr {
      td:first-child {
        width: 20%;
        font-weight: bold;
      }
    }
    th, td {
      border-bottom: 1px solid #ccc;
      padding: 10px;
      margin: 0;
      line-height: 1.9;
      box-sizing: border-box;
      p {
        margin: 0;
        padding: 0;
        line-height: 1;
      }
    }
  }
}

@media (max-width: 768px) {
	.cms-content {
		padding: 0;
	}
}

.react-datepicker { font-size: 1em !important }
.react-datepicker__header { padding-top: 0.8em !important}
.react-datepicker__month { margin: 0.4em 1em !important}
.react-datepicker__day-name, .react-datepicker__day {
  width: 1.9em !important; 
  line-height: 1.9em !important;
  margin: 0.166em !important;
}
.react-datepicker__current-month { font-size: 1em !important }
.react-datepicker__navigation {
  top: 1em !important;
  line-height: 1.7em !important;
  border: 0.45em solid transparent !important;
}
.react-datepicker__navigation--previous { border-right-color: #ccc !important; left: 1em !important }
.react-datepicker__navigation--next { 
  border-left-color: #ccc !important; 
  right: 1em !important; 
  left: 220px !important 
}
.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker__time-container { width: 6em !important}
.react-datepicker-time__header { font-size: 1em !important }
.react-datepicker-time__header--time { padding-left: 0px !important; padding-right: 0px !important }
.react-datepicker__time-box { 
  margin-left: 0px !important; 
  margin-right: 0px !important; 
  width:100% !important
}
.react-datepicker__time-list { padding: 0 !important; }