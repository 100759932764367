.contact {
	&-text {
		padding: 0 24px;
		line-height: 1.8;
		font-size: 1.6rem;
		margin: 0;
    letter-spacing: 1px;
		a {
			color: #000000;
			font-weight: 700;
			text-decoration: none;
		}
	}
}