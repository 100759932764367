
.mv {
	width: 100%;
	margin: 0;
	padding: 0;
	position: relative;
	img {
		width: 100%;
		vertical-align: bottom;
	}
	&-info {
		text-align: center;
    width: 100%;
    max-width: 1000px;
    padding: 20px;
	}
}

@media (max-width: 768px) {
	.mv {
		&-info {
			padding: 20px 15px;
		}
	}
}

.liveList {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	margin-bottom: 16px;
	text-align: left;
	padding: 0 24px;
	&::before {
		content: "■";
	}
	dt {
		margin-left: 30px;
    padding-left: 8px;
    width: 16%;
		font-weight: 700;
		min-width: 149px;
		box-sizing: border-box;
	}
	dd {
		width: 84%;
		margin: 0;
		a {
			font-weight: 700;
			color: #000000;
			font-weight: 700;
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	&-new {
		dt {
			margin-left: 0 !important;
			padding-left: 8px !important;
		}
		&::before {
			display: inline-block;
			content: "NEW";
			background: red;
			color: #fff;
			font-size: 14px;
			padding: 3px 8px;
		}
	}
}

@media (max-width: 768px) {
	.liveList {
		display: block;
		padding: 0; 
		dt {
			display: inline-block;
			width: auto;
		}
		dd {
			width: 100%;
			margin: 0;
		}
	}
}

.lineAt {
	margin: 48px 0 0;
	text-align: center;
	img {
    max-width: 100%;
	}
}

.newsList {
	margin: 0;
	padding: 0 24px;
	li {
		position: relative;
		margin-bottom: 1rem;
		list-style-type: none;
		padding-left: 57px;
		font-weight: 700;
		a {
			font-weight: 700;
			color: #000000;
			font-weight: 700;
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
		&::before {
			content: "●";
			position: absolute;
			top: 0;
			left: 0;
		}
	}
	&-new {
		&::before {
			display: inline-block;
			content: "NEW" !important;
			background: red;
			color: #fff;
			font-size: 14px;
			padding: 3px 8px;
		}
	}
}

@media (max-width: 768px) {
	.newsList {
		padding: 0;
	}
}

.video {
	&-wrapper {
		padding: 0 24px;
		display: flex;
		flex-wrap: wrap;
	}
	&-item {
		width: 48%;
		margin: 0 1%;
    margin-bottom: 1rem;
		> p {
			text-align: center;
			font-size: 2.4rem;
			font-weight: 800;
			margin: 0.8rem 0 0;
		}
		> div {
			position: relative;
			padding-top: 56.25%;
			iframe {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
	}
}

@media (max-width: 768px) {
	.video {
		&-wrapper {
			padding: 0 24px;
			display: block;
		}
		&-item {
			width: 100%;
			margin: 0;
			margin-bottom: 1rem;
			> p {
				font-size: 1.75rem;
			}
		}
	}
}

.music {
	&-wrapper {
		display: flex;
    justify-content: space-around;
		flex-wrap: wrap;
		a {
			width: 30%;
			text-decoration: none;
			color: #000;
			margin-bottom: 1rem;
			text-align: center;
			&:hover {
				text-decoration: underline;
			}
			img {
				width: 100%;
			}
			h3 {
				font-size: 2.4rem;
				font-weight: 800;
				margin: 0 0 .5rem;
				line-height: 1.2;
			}
			p {
				margin: 0;
				font-size: 1.0rem;
			}
		}
	}
}

@media (max-width: 768px) {
	.music {
		&-wrapper {
			display: block;
			a {
				width: 100%;
				max-width: 350px;
				display: block;
				margin: 0 auto;
				text-decoration: none;
				color: #000;
				margin-bottom: 1rem;
				text-align: center;
				&:hover {
					text-decoration: underline;
				}
				img {
					width: 100%;
				}
				h3 {
					font-size: 1.75rem;
				}
				p {font-size: 1.4rem;
				}
			}
		}
	}
	
}
