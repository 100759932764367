.paging {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 30px 0 0;
	font-size: 24px;
	&-btn {
		font-size: 32px;
		color: #000;
		&:hover {
			opacity: .7;
		}
		&-disabled {
			color: #ccc;
			cursor: not-allowed;
			&:hover {
				opacity: 1;
			}
		}
	}
	&-list {
		margin: 0;
		padding: 0 20px;
		display: flex;
		list-style-type: none;
		&-item {
			a {
				display: block;
				padding: 0 10px;
				text-decoration: none;
				color: #666;
				&:hover {
					opacity: .7;
				}
				&.active {
					color: #000;
				}
			}
		}
	}
}