.password {
	&-wrapper {
		position: fixed;
		background: #fff;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		z-index: 9998;
		width: 80%;
		max-width: 500px;
		padding: 20px 30px;
		border-radius: 6px;
	}
	&-overlay {
		position: fixed;
		z-index: 9997;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,.5);
	}
	&-title {
		margin: 0;
		padding: 0;
	}
	&-text {
		margin: 0 0 10px;
		padding: 0;
	}
	&-close {
		line-height: 1;
		position: absolute;
		top: 10px;
		right: 10px;
		font-size: 24px;
		cursor: pointer;
		&:hover {
			opacity: .7;
		}
		svg {
			vertical-align: top;
		}
	}
}