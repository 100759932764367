.list {
	display: flex;
	margin: 0 0 15px;
	padding: 0;
	&:last-child {
		margin: 0;
	}
	&-item {
		width: 100%;
		margin: 0;
		&-bisected {
			padding: 0 10px 0 0;
			width: 50%;
			+ .list-item-bisected {
				padding: 0 0 0 10px;
			}
		}
		dt ,
		dd {
			margin: 0;
			padding: 0;
		}
		dt {
			font-weight: bold;
			margin: 0 0 5px;
		}
	}
}

.required {
	color: red;
}




@media (max-width: 768px) {
	.list {
		display: block;
		margin: 0 0 0;
		&:last-child {
			.list-item {
				&:last-child {
					margin: 0;
				}
			}
		}
		&-item {
			margin: 0 0 15px;
			&-bisected {
				padding: 0;
				width: 100%;
				+ .list-item-bisected {
					padding: 0;
				}
			}
		}
	}
}