.liveHead {
	display: flex;
	justify-content: space-between;
	font-size: 1.6rem;
	padding: 0 24px;
	margin: 0 0 40px;
	p {
		font-weight: 700;
		margin: 0;
	}
}

@media (max-width: 768px) {
	.liveHead {
		padding: 0;
		margin: 0 0 20px;
	}
	
}


.liveList {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	margin-bottom: 16px;
	text-align: left;
	padding: 0 24px; 
	&::before {
		content: "■";
	}
	dt {
    padding-left: 8px;
    width: 16%;
		font-weight: 700;
		min-width: 149px;
	}
	dd {
		width: 84%;
		margin: 0;
		a {
			font-weight: 700;
			color: #000000;
			font-weight: 700;
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
	}
}

@media (max-width: 768px) {
	.liveList {
		display: block;
		padding: 0;
		dt {
			display: inline-block;
			width: auto;
		}
		dd {
			width: 100%;
			margin: 0;
		}
	}
}

.liveNo {
	text-align: center;
	font-size: 1.6rem;
}