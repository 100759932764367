.footer {
	padding: 40px 16px 16px;
	text-align: center;
	&-sns {
		display: flex;
		justify-content: center;
		margin: 0 0 30px 0;
		padding: 0;
		li {
			list-style-type: none;
			margin: 0 4px;
			&:hover {
				opacity: .7;
			}
		}
	}
	&-nav {
		display: flex;
		justify-content: center;
		padding: 0;
		li {
			list-style-type: none;
			padding: 0 20px;
			a {
				color: #333;
				text-decoration: underline;
				&:hover {
					text-decoration: none;
					opacity: .7;
				}
			}
		}
	}
}


@media (max-width: 768px) {
	.footer {
		&-nav {
			display: block;
			li {
				padding: 10px 0;
			}
		}
	}
}