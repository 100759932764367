.loading {
	width: 100%;
	text-align: center;
	padding: 40px 0;
	svg {
		font-size: 6rem;
		color: #ccc;
		animation: load 3s infinite linear;
	}
	&-overlay {
		position: fixed;
		z-index: 9999;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,.5);
		svg {
			font-size: 6rem;
			color: #fff;
			animation: load 3s infinite linear;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
		}
	}
}



@keyframes load {
  0% {
    transform: rotate(0);
  }
  99% {
    transform: rotate(359deg);
  }
}