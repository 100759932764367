
.mv {
	width: 100%;
	margin: 0;
	padding: 0;
	position: relative;
	img {
		width: 100%;
		vertical-align: bottom;
	}
}

.obake1 {
	position: absolute;
	top: 50%;
	left: 23%;
	width: 14%;
	animation: swing linear 5s infinite;
	img {
		width: 100%;
	}
}


.obake2 {
	position: absolute;
	bottom: -33%;
	right: 2%;
	width: 30%;
	&-item {
		width: 44% !important;
		animation: swing linear 4s infinite reverse;
	}
	&-txt {
		position: absolute;
		bottom: 27%;
		right: 0;
		width: 65% !important;
		animation: txt linear 4s infinite;
	}
}

.obake3 {
	position: absolute;
	top: 50%;
	right: -4%;
	width: 14%;
	animation: swing2 linear 5s infinite;
	img {
		width: 100%;
	}
}


.obake4 {
	position: absolute;
	bottom: -5%;
	left: 4%;
	width: 14%;
	animation: swing3 linear 5s infinite;
	img {
		width: 100%;
	}
}

@media (max-width: 768px) {
	.obake4 {
		bottom: -1.5%;
	}
}

.product {
	&-list {
		display: flex;
		flex-wrap: wrap;
	}
	&-item {
		width: 40%;
		margin: 20px 5% 20px;
		padding: 20px;
		text-align: center;
		border: 1px solid #ccc;
		border-radius: 6px;
		img {
			width: 100%;
		}
	}
	&-name {
		margin: 0;
		font-size: 1.6rem;
	}
	&-price {
		font-size: 1.4rem;
		font-weight: bold;
		margin: 10px 0 10px;
	}
	&-catch ,
	&-short {
		margin: 0;
		color: #666;
	}
}

@media (max-width: 768px) {
	.product {
		&-list {
			display: block;
			padding: 0 15px;
		}
		&-item {
			width: 100%;
			margin: 0 0 20px;
			padding: 20px;
			&:last-child {
				margin: 0;
			}
			img {
				width: 100%;
			}
		}
		&-name {
			margin: 0;
			font-size: 1.6rem;
		}
		&-price {
			margin: 10px 0 10px;
		}
	}
}



@keyframes swing {
	0% { transform: rotate(0deg); }
	25% { transform: rotate(10deg); }
	50% { transform: rotate(0deg); }
	75% { transform: rotate(-10deg); }
	100% { transform: rotate(0deg); }
}

@keyframes swing2 {
	0% { transform: rotate(-20deg) translateX(0); }
	50% { transform: rotate(-10deg) translateX(30px); }
	100% { transform: rotate(-20deg) translateX(0); }
}

@keyframes swing3 {
	0% { transform: rotate(-20deg) translateY(0); }
	50% { transform: rotate(-10deg) translateY(30px); }
	100% { transform: rotate(-20deg) translateY(0); }
}



@keyframes txt {
	0% { opacity: 1; }
	25% { opacity: 0; }
	50% { opacity: 1; }
	75% { opacity: 0; }
	100% { opacity: 1; }
}
