.cms {
	&-title {
    font-size: 2.4rem;
		font-weight: bold;
		margin: 0 0 48px;
		text-align: center;
	}
}


@media (max-width: 768px) {
	.cms {
		&-title {
			margin: 0 0 24px;
			font-weight: normal;
		}
	}
}

