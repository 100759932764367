.btn {
	&-warapper {
		text-align: center;
		padding: 0;
		clear: both;
		&__right {
			text-align: right;
		}
		a, button {
			display: inline-block;
			color: #ffffff;
			background: #07bc0c;
			padding: 12px 20px;
			border-radius: 6px;
			text-decoration: none;
			outline: none;
			border: none;
			cursor: pointer;
			+ a , + button {
				margin-left: 10px;
			}
		}
	}
	&-submit {
		background: #07bc0c;
		&:disabled {
			opacity: .5;
			cursor: not-allowed;
		}
	}
	&-back {
		background: #e74c3c !important;
	}
	&-glay {
    background: #8e8e8e !important;
		&:disabled {
			opacity: .5;
			cursor: not-allowed;
		}
	}
}


@media (max-width: 768px) {
	.btn {
		&-warapper {
			a, button {
				display: block;
				width: 100%;
				+ a , + button {
					margin-left: 0;
					margin-top: 10px;
				}
			}
		}
	}
}



.title {
	clear: both;
	text-align: center;
	margin: 0 0 20px;
	font-size: 3rem;
}

.sub-title {
	clear: both;
	font-size: 2rem;
	margin: 0;
	&-wrapper {
		margin: 0 0 10px;
		&--thanks {
			text-align: center;
		}
		&-top {
			margin-top: 40px;
		}
	}
	&-info {
		margin: 0;
	}
}

@media (max-width: 768px) {
	.title {
    font-size: 24px;
		padding-top: 20px;
	}
}



.main {
	&-wrapper {
		width: 100%;
		max-width: 1000px;
		margin: 0 auto;
		padding: 20px 0;
		&-top {
			padding: 140px 0 0;
		}
	}
}

@media (max-width: 768px) {
	.main {
		&-wrapper {
			padding: 20px 15px;
			&-top {
				padding: 60px 0 0;
			}
		}
	}
}


.wrapper {
	border: 2px solid #3e3e3e;
	border-radius: 6px;
	padding: 20px;
	overflow: hidden;
	margin: 0 0 30px;
	&:last-child {
		margin: 0;
	}
}


.input {
	width: 100%;
	border: 1px solid #3e3e3e;
	border-radius: 6px;
	padding: 10px 16px;
	color: #333;
	font-size: 1.6rem;
	&-error {
		border: 1px solid #f44336;
		color: #f44336;
		&::placeholder {
			color: #f44336;
		}
		&-text {
			font-size: 14px;
			color: #f44336;
		}
	}
	&-select {
		padding: 6px 16px;
	}
}

.select {
	width: 20%;
	padding: 2px 5px;
	border: 1px solid #ccc;
	border-radius: 6px;
	margin: 10px 5px 10px 0;
	outline: none;
	text-align: center;
}

.common-title {
	margin-bottom: 48px;
	font-size: 48px;
	font-weight: 400;
	position: relative;
	text-align: center;
	font-family: 'Joti One', 'M PLUS 1p', '游ゴシック Medium', YuGothic, YuGothicM, 'Hiragino Kaku Gothic ProN', 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, sans-serif;
	&::before ,
	&::after {
		content: "";
    position: absolute;
    top: 50%;
    width: 32%;
    height: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
	}
	&::before {
    left: 0;
	}
	&::after {
		right: 0;
	}
	&__long {
		&::before ,
		&::after {
			width: 22%;
		}
	}
}

@media (max-width: 768px) {
	.common-title {
		font-size: 40px;
		margin: 0 0 .5rem;
		&::before ,
		&::after {
			content: none;
		}
	}
}

.common-wrapper {
	margin: 0 auto 80px;
	&:last-child {
		margin: 0 auto;
	}
}

@media (max-width: 768px) {
	.common-wrapper {
		margin: 0 auto 64px;
		&:last-child {
			margin: 0 auto;
		}
	}
}

.mv {
	width: 100%;
	height: 462px;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: auto 462px;
	background-color: #fcfcfc;
}

@media (max-width: 768px) {
	.mv {
		height: 0;
    margin-bottom: 30px;
	}
}

.cms-head-img {
	margin-bottom: 10px;
	padding: 0 24px;
	img {
		width: auto;
		max-width: 100%;
	}
}

@media (max-width: 768px) {
	.cms-head-img {
		padding: 0;
	}
}

.form-item {
	margin: 0 0 10px;
	&-group {
		margin: 0;
		&-label {
			font-weight: bold;
			margin: 0 0 5px;
		}
		&-field {
			margin: 0;
			padding: 0;
		}
	}
}

.order-info {
	div {
		display: flex;
		width: 100%;
		margin: 0 0 20px 0;
		&:last-child {
			margin: 0;
		}
		dt {
			width: 140px;
		}
		dd {
			margin: 0;
		}
	}
}

@media (max-width: 768px) {
	.order-info {
		div {
			display: block;
			width: 100%;
			margin: 0 0 20px 0;
			&:last-child {
				margin: 0;
			}
			dt {
				width: auto;
			}
		}
	}
}