.header {
	padding: 14px 35px;
	border-bottom: 1px solid #e8e8e8;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 800;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	background-color: #FAFAFA;
	min-height: 63px;
	h1 {
		margin: 0;
		position: absolute;
    top: 8px;
    left: 32px;
		img {
			width: 100%;
			max-width: 320px;
			vertical-align: top;
			filter: drop-shadow(0 0 8px #FFFFFF) drop-shadow(0 0 8px #FFFFFF);
		}
	}
	&-btn {
		.header-list {
			display: none;
		}
		a, button {
			display: inline-block;
			color: #ffffff;
			background: #8e8e8e;
			padding: 10px 12px 12px;
			border-radius: 6px;
			font-size: 12px;
			text-decoration: none;
			outline: none;
			border: none;
			line-height: 1;
			cursor: pointer;
			+ a , + button {
				margin-left: 10px;
			}
			&:hover {
				text-decoration: underline;
			}
		}
		&-account {
			position: relative;
			padding: 10px 24px 12px 12px !important;
			svg {
				position: absolute;
				top: 50%;
				right: 12px;
				transform: translateY(-50%);
				line-height: 1;
			}
			&-wrapper {
				position: relative;
				display: inline-block;
				margin-left: 10px;
			}
			&:hover {
				text-decoration: none !important;
			}
		}
		&-cart {
			position: relative;
			padding: 10px 42px 12px 12px !important;
			span {
				border-radius: 0 6px 6px 0;
				position: absolute;
				height: 100%;
				width: 34px;
				padding: 5px;
				display: flex;
				justify-content: center;
				align-items: center;
				top: 0;
				right: 0;
				background: #000;
			}
		}
	}
	&-list {
		margin: 0 20px 0 0;
		padding: 0;
		display: flex;
		li {
			list-style-type: none;
			a {
				font-family: YuGothic, YuGothicM;
				font-size: 23px;
				font-weight: 700;
				line-height: 1;
				color: #000;
				text-shadow: 0 1px 0 #fff;
				display: block;
				padding: 8px 16px;
				text-decoration: none;
			}
		}
	}
	&-account {
		display: none;
		position: absolute;
		top: 105%;
		right: 0;
		width: 120%;
		background: #8e8e8e;
		border-radius: 6px;
		padding: 10px 0;
		text-align: center;
		ul {
			list-style-type: none;
			margin: 0;
			padding: 0;
			a, button {
				display: block;
				width: 100%;
				text-align: center;
			}
		}
		&-active {
			display: block;
		}
	}
}

@media (max-width: 1400px) {
	.header {
		min-height: 48px;
		h1 {
			top: 8px;
			left: 16px;
			img {
				max-width: 192px;
			}
		}
		&-btn {
			position: absolute;
			left: 0;
			top: -100vh;
			overflow-y: scroll;
			transition: 0.8s;
			width: 100vw;
			height: 100vh;
			background-color: rgba(0, 0, 0, 0.8);
			transition: 0.8s;
			z-index: 2000;
			padding: 60px 25px 0;
			box-sizing: border-box;
			display: block;
			.header-list {
				margin: 0;
				display: block;
				a {
					color: #fff;
					font-size: 16px;
				}
			}
			&.active {
				top: 0;
			}
			a, button {
				width: 100%;
				display: block;
				color: #ffffff;
				background: none;
				border: 1px solid #fff;
				font-size: 16px;
				margin: 0 0 20px;
				+ a , + button {
					margin-left: 0;
				}
			}
			&-account {
				display: none !important;
				&-wrapper {
					display: block;
					margin-left: 0;
				}
			}
		}
		&-list {
			display: none;
		}
		&-account {
			display: block;
			padding: 0;
			text-align: left;
			width: 100%;
			background: none;
			ul {
				a, button {
					text-align: left;
				}
			}
		}
	}
}

.spMenu {
	display: none;
}

@media (max-width: 1400px) {
	.spMenu {
		display: block;
		position: absolute;
		right: 16px;
		top: 16px;
		width: 24px;
		height: 20px;
		cursor: pointer;
		z-index: 9999;
		span {
			position: absolute;
			left: 0;
			width: 100%;
			height: 2px;
			display: inline-block;
			transition: all 0.4s;
			box-sizing: border-box;
			background-color: rgba(0, 0, 0, 0.8);
			border-radius: 6px;
			&:nth-of-type(1) {
				top: 0;
			}
			&:nth-of-type(2) {
				top: 9px;
			}
			&:nth-of-type(3) {
				bottom: 0;
			}
		}
		&.active {
			span {
				background-color: rgba(255, 255, 255, .8);
				&:nth-of-type(1) {
					transform: translateY(9px) rotate(-45deg);
				}
				&:nth-of-type(2) {
					opacity: 0;
				}
				&:nth-of-type(3) {
					transform: translateY(-9px) rotate(45deg);
				}
			}
		}
	}
}