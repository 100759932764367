.account-nav {
	border: 1px solid #ccc;
	margin: 0;
	padding: 0;
	list-style-type: none;
	border-radius: 6px;
	overflow: hidden;
	margin:  0 0 10px;
	&:last-child {
		margin: 0;
	}
	&-wrapper {
		width: 23%;
	}
	&-item {
		border-bottom: 1px solid #ccc;
		&:last-child {
			border: 0px;
		}
		&-inner {
			display: flex;
			border: none;
			background: none;
			align-items: center;
			&-icon {
				position: relative;
				background: #000;
				width: 40px;
				height: 40px;
				margin: 0 10px 0 0;
				color: #fff;
				svg {
					position: absolute;
					top: 50%;
					left: 50%;
					font-size: 20px;
					transform: translate(-50%, -50%);
				}
			}
			&:hover {
				text-decoration: none;
				background: #ccc;
				color: #fff;
			}
			&-active {
				background: #000;
				color: #fff;
				&:hover {
					background: #000;
					color: #fff;
				}
			}
		}
	}
}

.account-wrapper {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}

.account-body {
	width: 75%;
	border: 1px solid #ccc;
	padding: 20px 30px;
	border-radius: 6px;
	&-title {
		margin: 0;
		padding: 0;
	}
	&-text {
		margin: 0 0 10px;
		padding: 0;
	}
}

.account-table {
  border-collapse: collapse;
  border-spacing: 0;
	width: 100%;
	border-bottom: 1px solid #000;
	tr {
		td, th {
			padding: 10px 10px;
			width: 16.6%;
			border-top: 1px solid #000;
			a {
				text-decoration: underline;
				&:hover {
					text-decoration: none;
					opacity: .7;
				}
			}
		}
	}
	tbody {
		tr {
			td {
				text-align: center;
			}
		}
	}
}


@media (max-width: 768px) {
	.account-wrapper {
		display: block;
	}
	.account-nav {
		&-wrapper {
			width: 100%;
			margin: 0 0 10px;
		}
	}
	.account-body {
		width: 100%;
	}
	.account-table {
		&-wrapper {
			width: 100%;
			overflow-x: scroll;
		}
		width: 100%;
		min-width: 500px;
	}

}