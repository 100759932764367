.coupon {
	display: flex;
	padding: 0;
	margin: 0;
	justify-content: space-between;
	li {
		list-style-type: none;
		&:first-child {
			max-width: 869px;
			width: 100%;
			padding: 0 0 0 0;
		}
		&:last-child {
			text-align: right;
			width: 95px;
		}
	}
}